import { template as template_50c31e6e6edd490a9d0282b46ed3d445 } from "@ember/template-compiler";
const FKLabel = template_50c31e6e6edd490a9d0282b46ed3d445(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
