import { template as template_2314d5edaa2a49288a6e02c3cdc39a78 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_2314d5edaa2a49288a6e02c3cdc39a78(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
