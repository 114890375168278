import { template as template_f50ccac6524a4c5096fe212f1849f1fc } from "@ember/template-compiler";
const FKControlMenuContainer = template_f50ccac6524a4c5096fe212f1849f1fc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
