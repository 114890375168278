import { template as template_a8a6c52f651e41cdb7956652866c3cc9 } from "@ember/template-compiler";
const FKText = template_a8a6c52f651e41cdb7956652866c3cc9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
