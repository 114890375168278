import { template as template_4edf9f2f5f334ae1b10621277f70e2b6 } from "@ember/template-compiler";
const WelcomeHeader = template_4edf9f2f5f334ae1b10621277f70e2b6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
